import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import landingPage from "./pages/landingPage/landingPage";
import { isMobileOnly } from 'react-device-detect';
import './App.css';

class App extends Component {

  componentWillMount() {
    if (isMobileOnly) {
      window.location.href = "https://m." + window.location.hostname
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/" exact component={landingPage} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
