import React, { Component } from 'react';
import LogoSobrus from '../../images/sobrus_logo.png';
import MonOrdonnancelogo from '../../images/LogoMonOrdonnance.png';
import ordonnanceGIF from '../../images/ordonnance.gif';
import docHeads from '../../images/docHeads.png';
import pharmaLogo from '../../images/pharmaLogo.png';
import smilyFace from '../../images/smilyFace.png';
import arrow_icon from '../../images/arrow_icon.png';
import SobrusLogoFooter from "../../images/sobrus_logo_footer.png";
import sprintePhone from "../../images/spritePhone.png";
import sprinteEmail from "../../images/spriteEmail.png";
import spriteSite from "../../images/spriteSite.png";
import spriteLocalisation from "../../images/spriteLocalisation.png";
import logo_footer from '../../images/logo_footer.png';
import dataBase from '../../images/dataBase.png';
import loading from '../../images/loading.png';
import search from '../../images/search.png';
import monordonnanceEmail from '../../images/monordonnanceEmail.png';
import WebSitePolitique from './webSitePolitique';
import TermsOfService from './termsOfService';
import { TOKEN_URL } from '../../config';
import MulteStepForm from '../../helpers/MulteStepForm/MulteStepForm';
import './landingPage.css';

class landingPage extends Component {
    constructor(props) {
        super(props);
        this.scrollLestner = null;
        this.state = {
            privacypolicy: 0,
        };
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        document.title = "MonOrdonnance - Pour une ordonnance digitalisée pour tous"
        let path = window.location.href.slice(32)
        if (path === "/#privacypolicy") {
            this.setState({ privacypolicy: 1 })
        } else if (path === "/#termsofservice") {
            this.setState({ privacypolicy: 2 })
        } else {
            window.addEventListener("scroll", this.handleScroll);
        }
        if ((path !== "/#privacypolicy" && this.state.privacypolicy === 1) || (path !== "/#termsofservice" && this.state.privacypolicy === 2)) {
            this.setState({ privacypolicy: 0 })
        }
    }

    componentDidUpdate() {
        let path = window.location.href.slice(32)
        if (path === "/#privacypolicy" || path === "/#termsofservice") {
            window.removeEventListener("scroll", this.handleScroll, false)
        }

        if ((path !== "/#privacypolicy" && this.state.privacypolicy === 1) || (path !== "/#termsofservice" && this.state.privacypolicy === 2)) {
            this.setState({ privacypolicy: 0 })
        }

        if (path === "/#privacypolicy" && (this.state.privacypolicy === 0 || this.state.privacypolicy === 2)) {
            this.setState({ privacypolicy: 1 })
        } else if (path === "/#termsofservice" && (this.state.privacypolicy === 0 || this.state.privacypolicy === 1)) {
            this.setState({ privacypolicy: 2 })
        }
        console.log(this.state.privacypolicy);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, false)
    }

    handleScroll = () => {
        let path = window.location.href.slice(32)
        if (path !== "/#privacypolicy" && path !== "/#termsofservice") {
            if (window.scrollY < 703) {
                document.getElementById("accueil_for_scroll").className = "navbar scroll";
                document.getElementById("comment_for_scroll").className = "navbar";
                document.getElementById("presentation_for_scroll").className = "navbar";
                document.getElementById("contact_for_scroll").className = "navbar";
            } else if (window.scrollY > 703 && window.scrollY < 1201) {
                document.getElementById("presentation_for_scroll").className = "navbar scroll";
                document.getElementById("accueil_for_scroll").className = "navbar";
                document.getElementById("comment_for_scroll").className = "navbar";
                document.getElementById("contact_for_scroll").className = "navbar";
            } else if (window.scrollY > 1201 && window.scrollY < 1730) {
                document.getElementById("comment_for_scroll").className = "navbar scroll";
                document.getElementById("presentation_for_scroll").className = "navbar";
                document.getElementById("accueil_for_scroll").className = "navbar";
                document.getElementById("contact_for_scroll").className = "navbar";
            } else if (window.scrollY > 1730) {
                document.getElementById("comment_for_scroll").className = "navbar";
                document.getElementById("contact_for_scroll").className = "navbar scroll";
                document.getElementById("accueil_for_scroll").className = "navbar";
                document.getElementById("presentation_for_scroll").className = "navbar";
            } else {
                document.querySelector(".navbar").className = "navbar";
            }
        }
    };

    render() {
        return (
            <div className="landingPage">
                <div className="fixed_header">
                    <div className="landing_page_top_header">
                        <div className="landing_page_top_header_container">
                            <p><i className="fas fa-phone-square-alt"></i>0530 500 500</p>
                            <div className="headerButtons">
                                <a href="#contact" className="landing_page_top_header_bleu">S'inscrire</a>
                                <a className="landing_page_top_header_button" onClick={() => {
                                    window.location.href = TOKEN_URL;
                                }}>SE CONNECTER</a>
                            </div>
                        </div>
                    </div>

                    <div className="landing_page_bottom_header">
                        <div className="landing_page_bottom_header_container">
                            <div className="landing_page_logos">
                                <a className="landing_page_logo" onClick={() => this.setState({
                                    privacypolicy: 0
                                }, () => document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" }))}>
                                    <img src={MonOrdonnancelogo} onClick={() => {
                                            window.location.href = "#accueil";
                                            document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" });
                                        }}  />
                                    <div className="logo_sep"></div>
                                </a>
                                <a href="https://sobrus.com/" style={{ textDecoration: "none" }} target="_blank">
                                    <div className="offert_logo">
                                        <span>Offert par</span>
                                        <img src={LogoSobrus} alt="sobrus" />
                                    </div>
                                </a>
                            </div>
                            <div className="landing_page_navbar">
                                {this.state.privacypolicy === 0 ?
                                    <>
                                        <a className="navbar" id="accueil_for_scroll" onClick={() => {
                                            window.location.href = "#accueil";
                                            document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" });
                                        }} >Accueil</a>
                                        <a className="navbar" id="presentation_for_scroll" href="#presentation">Présentation</a>
                                        <a className="navbar" id="comment_for_scroll" href="#how_btn">Comment ça marche ?</a>
                                        <a className="navbar" id="contact_for_scroll" href="#contact">contact</a>
                                    </> : this.state.privacypolicy === 1 || this.state.privacypolicy === 2 ?
                                        <>
                                            <a className="navbar" href="#accueil" onClick={() => this.setState({
                                                privacypolicy: 0
                                            })}>Accueil</a>
                                            <a className="navbar" href="#presentation" onClick={() => this.setState({
                                                privacypolicy: 0
                                            })}>Présentation</a>
                                            <a className="navbar" href="#how_btn" onClick={() => this.setState({
                                                privacypolicy: 0
                                            })}>Comment ça marche ?</a>
                                            <a className="navbar" href="#contact" onClick={() => this.setState({
                                                privacypolicy: 0
                                            })}>contact</a>
                                        </> : ""}

                            </div>
                        </div>
                    </div>
                </div>
                {this.state.privacypolicy === 0 ?
                    <>
                        <div className="landing_page_body" id="accueil" >
                            <div className="ordonnance_illustration">
                                <img src={ordonnanceGIF} />
                            </div>
                            <a href="#contact" className="landing_page_top_header_button" id="presentation">Demandez votre compte</a>
                        </div>


                        <div className="landing_page_presentation" id="presentation" >
                            <div className="landing_page_presentation_bloc">
                                <div className=" landing_page_presentation_bloc_left">
                                    <div className="clanding_page_presentation_title_left">
                                        <h1>Pourquoi utiliser<br></br>
                                            <strong>monordonnance ?</strong></h1>
                                        <div className="sep_title"></div>
                                    </div>
                                    <ul >
                                        <li className="Landing_page_icons">
                                            <img src={dataBase} />
                                            <p>Base de produits complète et à jour</p>
                                        </li>
                                        <li className="Landing_page_icons">
                                            <img src={loading} />
                                            <p>Gain de temps au quotidien</p>
                                        </li>
                                        <li className="Landing_page_icons">
                                            <img src={search} />
                                            <p>Parfaite traçabilité des prescriptions</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="landing_page_presentation_bloc_right">
                                    <div className="clanding_page_presentation_title_left">
                                        <h1>Monordonnance<br></br><strong>c'est quoi ?</strong></h1>
                                        <div className="sep_title"></div>
                                    </div>
                                    <p>Le premier logiciel en Afrique, 100% gratuit, dédié aux médecins spécialistes et généralistes, qui vous permet de gérer vos ordonnances et prescriptions médicales en toute simplicité grâce à son interface intuitive et une équipe de Support disponible.
                    Il vous donne également accès à +75.000 fiches de médicaments et de produits de parapharmacie.</p>
                                </div>
                            </div>
                        </div>


                        <div className="landing_page_acceuil_avantages" id="how_btn">
                            <div className="landing_page_acceuil_avantages_carde">
                                <div className="avantage_bloc">
                                    <img src={docHeads} className="doctor_icon" />
                                    <h4>Médecin</h4>
                                    <p>Pour une ordonnance plus professionnelle.</p>
                                </div>
                                <img src={arrow_icon} className="arrow_icon" />
                                <div className="avantage_bloc">
                                    <img src={pharmaLogo} className="doctor_icon" />
                                    <h4>Pharmacien</h4>
                                    <p>Pour une interprétation plus juste.</p>
                                </div>
                                <img src={arrow_icon} className="arrow_icon" />
                                <div className="avantage_bloc">
                                    <img src={smilyFace} className="doctor_icon" />
                                    <h4>Patient</h4>
                                    <p>Pour une meilleure santé.</p>
                                </div>
                            </div>
                        </div>

                        <section className="Landing_page_howitwork_wrapper">
                            <div className="Landing_page_howitwork_container">
                                <h1>comment <strong>ça marche ?</strong></h1>
                                <div className="sep_title"></div>
                                <p className="Landing_page_howitwork_paragraphe">A travers l’application MonOrdonnance, vous pouvez facilement rédiger vos ordonnances en trois étapes simples</p>

                                <div className="how_blocs">
                                    <div className="how_patient">
                                        <div className="how_number"><span>1</span></div>
                                        <i className="far fa-user"></i>
                                        <h4>Choisissez <br></br>le nom du patient</h4>
                                        <div className="how_blocs_sep"></div>
                                        <p>S’il est déjà existant, sinon vous pouvez le créer en 5 secondes.</p>
                                    </div>
                                    <div className="how_product">
                                        <div className="how_number"><span>2</span></div>
                                        <i className="fas fa-prescription-bottle"></i>
                                        <h4>AJOUTEZ LES PRODUITS <br></br> ET LEUR POSOLOGIE</h4>
                                        <div className="how_blocs_sep"></div>
                                        <p>Tous les produits sont créés, il suffit de les sélectionner en un clic.</p>
                                    </div>
                                    <div className="how_imprimer">
                                        <div className="how_number"><span>3</span></div>
                                        <i className="fas fa-print"></i>
                                        <h4>Imprimez votre<br></br>ordonnance</h4>
                                        <div className="how_blocs_sep"></div>
                                        <p>Et voilà, votre ordonnance est prête en 30 secondes.</p>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <section className="Landing_page_demande_wrapper" id="contact">
                            <div className="Landing_page_demande_Container">
                                <div className="Landing_page_demande_about_left ">
                                    <div className="title_left">
                                        <h1>Demandez votre compte<br></br><strong>gratuitement !</strong></h1>
                                        <div className="sep_title"></div>
                                    </div>
                                    <p>Notre équipe prendra contact avec vous pour répondre à toutes vos questions.</p>
                                    <ul className="Landing_page_demande_UL">
                                        <li><i className="fas fa-phone-square-alt Landing_page_demande_about_icon"></i><p>0530 500 500</p></li>
                                        <li><i className="fas fa-envelope Landing_page_demande_about_icon"></i><p>monordonnance@sobrus.com</p></li>
                                    </ul>
                                </div>
                                <div className="Landing_page_demande_about_right ">
                                    <MulteStepForm />
                                </div>
                            </div>
                        </section>
                    </>
                    : this.state.privacypolicy === 1 ? <WebSitePolitique id="privacypolicy" /> : this.state.privacypolicy === 2 ? <TermsOfService id="termsofservice" /> : ""

                }

                <div className="Footer">
                    <div className="Landing_page_footer">
                        <div className="col-4">
                            <img src={logo_footer} className="FooterdescriptionImg" style={{ width: "270px" }} />
                            <p className="FooterdescriptionPargaraphe">
                                Pour une ordonnance digitalisée pour tous, Sobrus lance MonOrdonnance, l'outil 100% gratuit, dédié aux médecins spécialistes et généralistes, qui permet de générer vos ordonnances et prescriptions médicales en toute simplicité. </p>
                        </div>
                        <div className="col-2 footerSolutions">
                            <h4>Nos solutions</h4>
                            <ul>
                                <li><a href="http://pharma.sobrus.com/" target="_blank">Sobrus Pharma</a></li>
                                <li><a href="https://labs.sobrus.com/" target="_blank">Sobrus Labs</a></li>
                                <li><a href="https://sns.sobrus.com/" target="_blank">Sobrus SNS</a></li>
                                <li><a href="https://med.sobrus.com/" target="_blank">Sobrus MED</a></li>
                            </ul>
                        </div>

                        <div className="footerSolutions" style={{ width: "25%" }}>
                            <h4>Nos outils</h4>
                            <ul>
                                <li><a href="http://monmedicament.ma/" target="_blank">Annuaire Médicaments</a></li>
                                <li  ><a href="https://monpharmacien.ma/" target="_blank">Annuaire Pharmacies</a></li>
                                <li><a href="http://chezledocteur.ma/" target="_blank">Annuaire Médecins</a></li>
                            </ul>
                        </div>

                        <div className="col-3 footerContact">
                            <h4>Contactez-nous</h4>
                            <ul>
                                <li><img src={sprintePhone} className="sprintePhone" />
                                    <p>0530 500 500</p></li>
                                <li><img src={sprinteEmail} className="sprinteEmail" />
                                    <p>
                                        <img src={monordonnanceEmail} alt="email" className="ImageEmail" style={{ height: "13px", marginTop: "6px" }} />
                                    </p></li>
                                <li><img src={spriteSite} className="spriteSite" />
                                    <p>sobrus.com</p></li>
                                <li>
                                    <img src={spriteLocalisation} className="spriteLocalisation" />
                                    <p>Villa N°222, rue Tetouan,<br /> Harhoura</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className="Brand">
                        <div className="Landing_page_footer_Brand">
                            <div className="footer_Brand">
                                {/* <a target="_blank" className="footerLinks" onClick={() => this.props.history.push("/termsOfService")}>Conditions générales d'utilisation</a> */}
                                <a target="_blank" className="footerLinks" onClick={() => {
                                    this.setState({ privacypolicy: 2 }, () => {
                                        window.location.href = "#termsofservice"
                                        document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" });
                                    })
                                }}>Conditions générales d'utilisation</a>
                                <a target="_blank" className="footerLinks" onClick={() => {
                                    this.setState({ privacypolicy: 1 }, () => {
                                        window.location.href = "#privacypolicy"
                                        document.getElementsByTagName("html")[0].scrollTo({ top: 0, behavior: "smooth" });
                                    })
                                }}>Politique de confidentialité</a>
                            </div>

                            <div className="footer_Brand">
                                <p className="sobrusLogoFooter">Offert par : </p>
                                <a href="https://sobrus.com/" target="_blank"><img src={SobrusLogoFooter} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default landingPage;
