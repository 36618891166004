import AsyncSelect from "react-select/async";
import React, { Component } from "react";
import axios from "axios";
// import authService from "../services/auth.service";
import { api_url } from "../config";

const authAxios = axios.create({
    baseURL: api_url,
});

const convertDataForSelectBox = (data, valueProperty, labelProperty) => {
    let convertedData = [];

    data.map((object) => {
        return convertedData.push({ value: valueProperty ? object[valueProperty] : "", label: labelProperty ? object[labelProperty] : "" });
    });
    return convertedData;
}

const convertDocDataForSelectBox = (data, valueProperty, labelProperty, LabelProperty2) => {
    let convertedData = [];

    data.map((object) => {
        return convertedData.push({ value: valueProperty ? object[valueProperty] : "", label: labelProperty ? (object[labelProperty] + " " + object[LabelProperty2]) : "" });
    });
    return convertedData;
}

class SearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fetchedUrl: this.props.fetchedUrl
        };
        this.noOptionsMessage = this.noOptionsMessage.bind(this)
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                
            }
        );
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchedUrl !== this.props.fetchedUrl) {
            this.setState({ fetchedUrl: this.props.fetchedUrl }, () => this.getData())
        }
    }

    getData = async (inputValue = null, callback = () => { }) => {
        try {
            const { HTTPMethod, receivedObject, optionLabelProperty, optionValueProperty, onInputChange, optionLabelProperty2, optionLabelProperty1 } = this.props;
            const { fetchedUrl } = this.state;

            if (HTTPMethod.toLocaleLowerCase() === "getdoctors") {
                let data = {}
                if (inputValue) {
                    data = {
                        fullName: inputValue,
                    }
                }
                let convertedArray = [];
                const response = await authAxios.post(`${fetchedUrl}`, data, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    convertedArray = convertDocDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty1, optionLabelProperty2);
                });
                callback(convertedArray);
            }

            if (HTTPMethod.toLocaleLowerCase() === "post") {
                let data = {};
                if (inputValue) {
                    data[optionLabelProperty] = {
                        value: inputValue,
                        operator: "contains",
                    };
                    onInputChange && onInputChange(inputValue)
                }
                const response = await authAxios.post(`${fetchedUrl}`, data, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data,
                    optionValueProperty, optionLabelProperty);
                callback(convertedArray);
                // for URLS that use GET HTTP method
            } else if (HTTPMethod.toLocaleLowerCase() === "get") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];
                const response = await authAxios.get(`${fetchedUrl}?dosage_content=${content}`, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    if (this.state.data.length === 0) {
                        convertedArray = convertDataForSelectBox([{ id: -1, content: inputValue }], optionValueProperty, optionLabelProperty)
                    } else {
                        convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty);
                    }
                });
                callback(convertedArray);
            } else if (HTTPMethod.toLocaleLowerCase() === "getspecialities") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];
                const response = await authAxios.get(`${fetchedUrl}?label=${content}`, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionLabelProperty, optionLabelProperty);
                });
                callback(convertedArray);
            }
            else if (HTTPMethod.toLocaleLowerCase() === "getallergies") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                const response = await authAxios.post(`${fetchedUrl}?label=${content}`, "", { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data,
                    optionValueProperty, optionLabelProperty);
                callback(convertedArray);
            } else if (HTTPMethod.toLocaleLowerCase() === "getcities" || HTTPMethod.toLocaleLowerCase() === "getpayers") {
                let content = "";
                if (inputValue) {
                    content = inputValue
                }
                let convertedArray = [];
                const response = await authAxios.get(`${fetchedUrl}?name=${content}`, { withCredentials: true });
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data }, () => {
                    convertedArray = convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty);
                });
                callback(convertedArray);
            }
        } catch (error) {
            this.setState({ is_loaded: false });
            console.log(error);
        }
    };
    noOptionsMessage() {
        return this.props.noOptoins;
    }

    render() {
        const { value, placeholder, onChange, optionLabelProperty, optionValueProperty, optionLabelProperty1, optionLabelProperty2, HTTPMethod } = this.props;
        return (
            <AsyncSelect
                value={value}
                noOptionsMessage={this.noOptionsMessage}
                width={"100%"}
                escapeClearsValue={true}
                isClearable={false}
                loadOptions={this.getData}
                placeholder={placeholder}
                onChange={onChange}
                defaultOptions={HTTPMethod.toLocaleLowerCase() === "getdoctors" ? convertDocDataForSelectBox(this.state.data, optionValueProperty, optionLabelProperty1, optionLabelProperty2) : convertDataForSelectBox(this.state.data, optionValueProperty, optionLabelProperty)}
                styles={{
                    loadingIndicator: (provided, state) => ({
                        ...provided,
                        display: "none",
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        fontSize: "12px",
                        color: "#7189a8"
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none",
                    }),
                    container: (provided, state) => ({
                        ...provided,
                        width:"100%"
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        borderColor: "#d9e3e8",
                        borderWidth: "2px",
                        borderRadius: "none",
                        boxShadow: "none",
                        ":hover": {
                            borderColor: "none",
                            borderWidth: "2px",
                        },
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        fontFamily: "OpenSans",
                        fontWeight: "600"
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected && "#18b1d4",
                        color: state.isSelected && "#fff",
                        fontFamily: "OpenSans",
                        fontWeight: "600",
                        ":hover": {
                            color: "#fff",
                            backgroundColor: "#18b1d4",
                        },
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        overflow: "initial"
                    }),
                    clearIndicator: (provided, state) => ({
                        ...provided,
                        paddingRight: 0,
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        paddingLeft: 0,
                    })
                }}
            />
        );
    }
}

export default SearchSelect;
