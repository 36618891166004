var api_url = "https://api.med.sobrus.ovh/api/";
var BASEURL = "https://api.med.sobrus.ovh/";
let MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
let TOKEN_URL = "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.med.sobrus.ovh/auth/token&prompt=login";

if (process.env.REACT_APP_ENV === "development") {
    api_url = "https://api.med.sobrus.ovh/api/";
    BASEURL = "https://api.med.sobrus.ovh/";
    MANAGE_PROFILE_URL = "https://account.sobrus.ovh/account";
    TOKEN_URL = "https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.med.sobrus.ovh/auth/token&prompt=login";
}

if (process.env.REACT_APP_ENV === "production") {
    api_url = "https://api.med.sobrus.com/api/";
    BASEURL = "https://api.med.sobrus.com/";
    MANAGE_PROFILE_URL = "https://account.sobrus.com/account";
    TOKEN_URL = "https://api.account.sobrus.com/authorize?response_type=code&client_id=10006&scope=openid%20offline_access&redirect_uri=https://api.med.sobrus.com/auth/token&prompt=login";
}

export { api_url, MANAGE_PROFILE_URL, TOKEN_URL, BASEURL };
