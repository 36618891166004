import React, { Component } from 'react';
import './MulteStepForm.css'
import SearchSelect from '../SearchSelect';
import { Transition } from 'react-transition-group';
import service from '../../services/preRegestration.sevice';
import HomeEmail from "../../images/HomeEmail.png"

const duration = 700;

const defaultStyle = {
    transition: `visibility 0s, opacity ${duration}ms ease-in-out, transform ${duration}ms`,
    opacity: 0,
    width: '0px',
    height: '0px',
    visibility: 'hidden',
    transform: 'scale(0.5)'
}

const transitionStyles = {
    entering: {
        visibility: 'visible', opacity: 1, width: 'unset',
        height: 'unset', transform: 'scale(1)'
    },
    entered: {
        visibility: 'visible', opacity: 1, width: 'unset',
        height: 'unset', transform: 'scale(1)'
    },
    exiting: {
        visibility: 'hidden', opacity: 0, width: '0px',
        height: '0px'
    },
    exited: {
        visibility: 'hidden', opacity: 0, width: '0px',
        height: '0px'
    },
};


class MulteStepForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: false,
            success: false,
            idToUpdate: 0,
            errors: {},
            stepOneFields: {
                ...this.initialStepOneFields,
            },
            stepTwoFields: {
                ...this.initialSteptwoFields,
            },
            selectedCity: [],
            specialities: [],
            selectedSpecialityId: "",
            selectedSpeciality: [],
            cities: [],
            selectedCityId: "",
            selectedCity: [],
            formStep: 1,
            inProp: false,
            preRegistratedId: null,
            finishedRegistration: 0,
            userRegesteredEmail: ""
        }
    }

    initialStepOneFields = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        speciality: null
    };

    initialSteptwoFields = {
        email: "",
        landLineNumber: "",
        city: null
    };

    preRegister = async () => {
        this.setState({ errors: {} }, async () => {
            let valid = await this.handleValidation();
            if (valid) {
                try {
                    let response = await service.preRegister(this.state.stepOneFields);
                    this.setState({ stepOneFields: { ...this.initialStepOneFields }, preRegistratedId: response.data.id, formStep: 2, inProp: true });
                } catch (error) {
                    console.warn(error);
                }
            }
        })
    };

    completePreRegistartion = async () => {
        this.setState({ errors: {} }, async () => {
            let valid = await this.handleValidationTwo();
            if (valid) {
                try {
                    let response = await service.completePreRegister(this.state.preRegistratedId, this.state.stepTwoFields)
                    this.setState({ stepTwoFields: { ...this.initialSteptwoFields }, userRegesteredEmail: response.data.email, finishedRegistration: 1, formStep: 3 });
                } catch (error) {
                    console.warn(error);
                }
            }
        })
    }

    handleValidation = async () => {
        const { stepOneFields } = this.state;
        let errors = {};
        let formIsValid = true;

        if (stepOneFields['firstName'] === "") {
            formIsValid = false;
            errors["firstName"] = "Champ obligatoire";
        }

        if (stepOneFields['lastName'] === "") {
            formIsValid = false;
            errors["lastName"] = "Champ obligatoire";
        }

        if (stepOneFields["mobileNumber"] !== "") {
            if (
                !stepOneFields["mobileNumber"].match(
                    /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/
                )
            ) {
                formIsValid = false;
                errors["mobileNumber"] = "Numéro invalide";
            }
        }

        if (stepOneFields['mobileNumber'] === "") {
            formIsValid = false;
            errors["mobileNumber"] = "Champ obligatoire";
        }

        if (stepOneFields['speciality'] === null || stepOneFields['speciality']['id'] === '') {
            formIsValid = false;
            errors["speciality"] = "Champ obligatoire";
        }

        this.setState({ errors: { ...this.state.errors, ...errors, } });
        return formIsValid;
    };

    handleValidationTwo = async () => {
        const { stepTwoFields } = this.state;
        let errors = {};
        let formIsValid = true;

        if (stepTwoFields['email'] === "") {
            formIsValid = false;
            errors["email"] = "Champ obligatoire";
        }

        if (stepTwoFields["landLineNumber"] !== "") {
            if (
                !stepTwoFields["landLineNumber"].match(
                    /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/
                )
            ) {
                formIsValid = false;
                errors["landLineNumber"] = "Numéro invalide";
            }
        }

        if (stepTwoFields['landLineNumber'] === "") {
            formIsValid = false;
            errors["landLineNumber"] = "Champ obligatoire";
        }

        if (stepTwoFields['city'] === null || stepTwoFields['city']['id'] === '') {
            formIsValid = false;
            errors["city"] = "Champ obligatoire";
        }

        this.setState({ errors: { ...this.state.errors, ...errors, } });
        return formIsValid;
    };

    handleChangeStepOne = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.stepOneFields));
        fieldsCopy[input] = e.target.value;
        this.setState({ stepOneFields: fieldsCopy });
    };

    handleChangeStepTwo = (input) => (e) => {
        let fieldsCopy = JSON.parse(JSON.stringify(this.state.stepTwoFields));
        fieldsCopy[input] = e.target.value;
        this.setState({ stepTwoFields: fieldsCopy });
    };

    render() {
        const { stepTwoFields, stepOneFields, errors } = this.state;
        return (
            <div className="MulteStepForm">
                {
                    this.state.formStep === 1 ?
                        <>
                            <div className="form_row">
                                <label htmlFor="" className="MultiStepFormLAbel">NOM DU MÉDECIN * : </label>
                                <input type="text" name="lastName" placeholder="Entrez le nom" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" error={errors["lastName"]} id="lastName" onChange={this.handleChangeStepOne("lastName")} value={stepOneFields["lastName"]} />
                                <p className="erreurInputText">{errors["lastName"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["lastName"]}</> : ""}</p>
                            </div>
                            <div className="form_row">
                                <label htmlFor="" className="MultiStepFormLAbel">PRÉNOM DU MÉDECIN * :</label>
                                <input type="text" name="first_name" placeholder="Entrez prénom" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" error={errors["firstName"]} id="firstName" onChange={this.handleChangeStepOne("firstName")} value={stepOneFields["firstName"]} />
                                <p className="erreurInputText">{errors["firstName"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["firstName"]}</> : ""}</p>
                            </div>
                            <div className="form_row">
                                <label htmlFor="selectized" className="MultiStepFormLAbel">SPÉCIALITÉ * :</label>
                                <SearchSelect
                                    fetchedUrl='/specialities'
                                    HTTPMethod="getspecialities"
                                    receivedObject="specialities"
                                    optionLabelProperty="label"
                                    optionValueProperty="id"
                                    value={this.state.selectedSpeciality}
                                    placeholder="Algologie - Médecine de la douleur"
                                    onChange={(e) => {
                                        if (e) {
                                            this.setState({ selectedSpeciality: [], specialities: [] }, () => {
                                                let speciality = {
                                                    id: e.value
                                                }
                                                let fieldsCopy = JSON.parse(JSON.stringify(this.state.stepOneFields));
                                                fieldsCopy['speciality'] = speciality;
                                                this.setState({
                                                    stepOneFields: fieldsCopy,
                                                    // specialities: [...this.state.specialities, specialities],
                                                    selectedSpecialityId: e.value,
                                                    selectedSpeciality: [...this.state.selectedSpeciality, e]
                                                })
                                            })
                                        }
                                    }}
                                />
                                <p className="erreurInputText">
                                    {errors["speciality"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["speciality"]}</> : ""}
                                </p>
                            </div>
                            <div className="form_row">
                                <label htmlFor="" className="MultiStepFormLAbel">TÉLÉPHONE PORTABLE * :</label>
                                <input type="number" name="mobileNumber" placeholder="Entrez le numéro de portable" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" error={errors["mobileNumber"]} id="mobileNumber" onChange={this.handleChangeStepOne("mobileNumber")} value={stepOneFields["mobileNumber"]} />
                                <p className="erreurInputText">{errors["mobileNumber"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["mobileNumber"]}</> : ""}</p>
                            </div>
                            <div className="form_controller">
                                <button type="submit" className="btn_demande" onClick={() => this.preRegister()}>Demandez votre compte</button>
                            </div>
                        </> : ""
                }
                {
                    <Transition in={this.state.formStep === 2} timeout={7000}>
                        {state => (
                            <div style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                            }}>
                                <>
                                    <div className="form_row">
                                        <label htmlFor="" className="MultiStepFormLAbel">EMAIL :</label>
                                        <input type="email" name="email" placeholder="Entrez votre email" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" error={errors["email"]} id="email" onChange={this.handleChangeStepTwo("email")} value={stepTwoFields["email"]} />
                                        <p className="erreurInputText">{errors["email"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["email"]}</> : ""}</p>
                                    </div>
                                    <div className="form_row">
                                        <label htmlFor="" className="MultiStepFormLAbel">TÉLÉPHONE FIXE DU CABINET :</label>
                                        <input type="number" name="landLineNumber" placeholder="Entrez votre numéro de fixe" className=" first_responder form-control popup_form_control" data-is_required="true" required="" autoComplete="off" error={errors["landLineNumber"]} id="landLineNumber" onChange={this.handleChangeStepTwo("landLineNumber")} value={stepTwoFields["landLineNumber"]} />
                                        <p className="erreurInputText">{errors["landLineNumber"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["landLineNumber"]}</> : ""}</p>
                                    </div>
                                    <div className="form_row">
                                        <label htmlFor="selectized" className="MultiStepFormLAbel">VILLE DU CABINET :</label>
                                        <SearchSelect
                                            fetchedUrl='/cities'
                                            HTTPMethod="getcities"
                                            receivedObject="cities"
                                            optionLabelProperty="name"
                                            optionValueProperty="id"
                                            value={this.state.selectedCity}
                                            placeholder="Ville"
                                            onChange={(e) => {
                                                if (e) {
                                                    this.setState({ selectedCity: [], cities: [] }, () => {
                                                        let city = {
                                                            id: e.value
                                                        }
                                                        let fieldsCopy = JSON.parse(JSON.stringify(this.state.stepTwoFields));
                                                        fieldsCopy['city'] = city;
                                                        this.setState({
                                                            stepTwoFields: fieldsCopy,
                                                            //cities: [...this.state.cities, cities],
                                                            selectedCityId: e.value,
                                                            selectedCity: [...this.state.selectedCity, e]
                                                        })
                                                    })
                                                }
                                            }}
                                        />
                                        <p className="erreurInputText">
                                            {errors["city"] ? <><i className="far fa-times-circle errorIcon"></i> {errors["city"]}</> : ""}
                                        </p>
                                    </div>
                                    <div className="form_controller">
                                        <button type="submit" className="btn_demande" onClick={() => this.completePreRegistartion()}>Demandez votre compte</button>
                                    </div>
                                </>
                            </div>
                        )}
                    </Transition>
                    // : ""
                }{this.state.finishedRegistration === 1 ?
                    <div className="lr-form ">
                        <div className="text-center lf-bicon">
                            <img src={HomeEmail} />
                        </div>
                        <h2 className="uppercase">Inscription prise en compte</h2>
                        <p className="">
                            {/* Nous vous avons envoyé un e-mail de confirmation à l’adresse :
                            <strong> {this.state.userRegesteredEmail} </strong>
                            Veuillez vérifier dans votre boîte e-mail et éventuellement dans les Spams si vous ne le retrouvez pas. */}
                            Votre pré-inscription est prise en compte. Nous allons vous contacter dans les plus brefs délais pour confirmer votre compte.
                        </p>
                        <p>
                            {/* Vous n’avez pas reçu d’email ? */}
                            <strong> Pour plus d’informations, contactez-nous au 05 30 500 500</strong>
                        </p>
                    </div> : ""
                }
            </div>
        );
    }
}

export default MulteStepForm;
