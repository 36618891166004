import axios from "axios";
import { api_url } from "../config";

const authAxios = axios.create({
    baseURL: api_url + "pre-registration"
});

class PreRegistrationService {
    constructor() {
        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    preRegister(data) {
        return authAxios.post(``, data);
    }

    completePreRegister(id, data) {
        return authAxios.patch(`complete/${id}`, data);
    }
}

export default new PreRegistrationService();
