import React, { Component } from 'react';
import "./landingPage.css"

class termsOfService extends Component {
    componentDidMount() {
        document.title = "MonOrdonnance - Conditions Générales d'Utilisation";
    }

    render() {
        return (
            <div className="termsOfService" id="termsOfService">
                <section className="Landing_page_howitwork_wrapper">
                    <div className="Landing_page_howitwork_container">
                        <h1>conditions générales <strong>d'utilisation</strong></h1>
                        <div className="sep_title"></div>
                        <p className="Landing_page_howitwork_paragraphe"> <span>MonOrdonnance</span> est un service proposé par la société <span>Sobrus</span>, société à responsabilité
                        limitée, dont le siège social sis <span>Villa 222, rue Tetouan, Harhoura - Témara</span>, immatriculée au RC de Rabat sous le numéro 88963 (ci-après dénommé le «<span>Prestataire</span>») .</p>
                        <div className="termsOfServiceContent">
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 1 – CHAMP D’APPLICATION</h2>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance est une application permettant à un médecin (ci-après le ou les
                                « <span>Utilisateur(s)</span> ») de créer un compte sur l’application, de prescrire des ordonnances
                        numériques, de consulter l’historique des prescriptions de ses patients (ci-après les «<span>Services</span>»).</p>
                                <p className="FooterdescriptionPargaraphe">Il est précisé que MonOrdonnance ne fournit ni ne livre aucun produit, notamment aucun
                                médicament, aux Utilisateurs ou à des tiers. Les prestations de MonOrdonnance se limitent à
                        donner aux médecins la possibilité de prescrir des ordonnances. MonOrdonnance n’intervient pas entre l’Utilisateur et les médecins/pharmaciens et ne facture aucune commission.</p>
                                <p className="FooterdescriptionPargaraphe">Les présentes conditions générales (ci-après les « <span>CGU</span> ») ont pour objet de définir les conditions dans lesquelles les Utilisateurs peuvent accéder aux Services et les utiliser.</p>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance se réserve le droit de déroger à certaines clauses des présentes Conditions Générales par l’établissement de conditions particulières.</p>
                                <p className="FooterdescriptionPargaraphe">Tout accès aux Services implique, de la part de l’Utilisateur, l'acceptation sans réserve des
                        CGU. Si l’Utilisateur ne souhaite pas être soumis aux CGU, il n’est pas autorisé à utiliser l’application et les Services qui y sont proposés.</p>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance est en droit d’y apporter toutes modifications qui lui paraîtront utiles; ces modifications prendront effet 72h après leur modification.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 2 – SERVICES PROPOSÉS</h2>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance propose les services suivants :</p>
                                <p className="FooterdescriptionPargaraphe headlines">- création de compte médecin (nom, prénom, adresse du cabinet, etc.) ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- rédaction de prescriptions médicales numériques ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- archivage de l’historique des prescriptions.</p>
                                <p className="FooterdescriptionPargaraphe">L’ensemble des services sont gratuits pour l’Utilisateur. Les services proposés peuvent évolués pour permettre une meilleur experience et faciliter le travail de nos utilisateurs.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 3 – CONDITIONS D’ACCÈS À L’APPLICATION</h2>
                                <p className="FooterdescriptionPargaraphe">Pour pouvoir valablement bénéficier des Services proposés, l’Utilisateur déclare  expressément avoir la pleine capacité juridique de conclure le présent contrat, c’est-à-dire être médecin et être inscrit à l’ordre des médecins.</p>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance accorde à l’Utilisateur, à titre personnel, le droit non exclusif et non-cessible d’accéder et d’utiliser les services sur un terminal compatible (Ordinateur, téléphone portable, tablette numérique) conformément aux CGU. Toute autre utilisation des services est strictement interdite et constitue un manquement aux CGU.</p>
                                <p className="FooterdescriptionPargaraphe">L’Utilisateur n’est pas autorisé à utiliser les Services à d’autres fins que celles mentionnées dans les CGU. L’utilisation de tout système ou logiciel automatisé visant à extraire toutes ou partie des données des Services à des fins commerciales est interdite. MonOrdonnance se réserve le droit de supprimer l’accès aux Services ainsi que d’intenter toute action ou réclamation nécessaire à l’effet de faire respecter cette interdiction, y compris dans le cadre de poursuites judiciaires.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 4 – CONDITIONS D’INSCRIPTION</h2>
                                <p className="FooterdescriptionPargaraphe">Pour créer un compte, l’Utilisateur doit fournir certaines informations indiquées au moyen d’un astérisque. L’Utilisateur garantit la véracité et l’exactitude des informations qu’il communique à MonOrdonnance et s’engage à les mettre immédiatement à jour en cas de changement de ces informations.</p>
                                <p className="FooterdescriptionPargaraphe">L’Utilisateur s’engage expressément à ne pas créer une fausse identité de nature à induire MonOrdonnance ou les tiers en erreur et à ne pas usurper l’identité d’une autre personne physique ou morale.</p>
                                <p className="FooterdescriptionPargaraphe">Dans les cas de fausse déclaration, usurpation d’identité, ou défaut de régularisation d’un profil inexact, MonOrdonnance se réserve le droit de suspendre ou de supprimer immédiatement sans préavis le compte de l’Utilisateur.</p>
                                <p className="FooterdescriptionPargaraphe">L’identifiant et le mot de passe du compte sont strictement personnels et confidentiels. La conservation et l’utilisation de l’identifiant et du mot de passe s’effectuent sous l’entière responsabilité de l’Utilisateur. L’Utilisateur s’oblige à les conserver secrets et à ne pas les divulguer sous quelque forme que ce soit. En cas de perte ou d’utilisation détournée de ses identifiants, l’Utilisateur s’engage à en informer immédiatement MonOrdonnance.</p>
                                <p className="FooterdescriptionPargaraphe">L’Utilisateur reconnaît que tout accès ou utilisation des Services sous son identité relève de son entière responsabilité</p>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance ne saurait en aucun cas être tenue responsable des conséquences préjudiciables à l’Utilisateur résultant des utilisations illicites, frauduleuses ou abusives de son identifiant ou mot de passe par un tiers non expressément autorisé par l’Utilisateur.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 5 – OBLIGATIONS DE L’UTILISATEUR</h2>
                                <p className="FooterdescriptionPargaraphe">L’Utilisateur s’engage à utiliser l’application et les Services qui y sont proposés en respectant la réglementation applicable et les CGU, et à ne pas les utiliser à des fins illégales. En cas de manquements de l’Utilisateur à ses obligations au titre des CGU, ce dernier est responsable des dommages causés par lui à MonOrdonnance ou aux tiers.</p>
                                <p className="FooterdescriptionPargaraphe">L’Utilisateur ne peut en aucun cas utiliser les Services afin d’accomplir notamment, sans que cette liste ne soit limitative, les activités suivantes :</p>
                                <p className="FooterdescriptionPargaraphe headlines">- transmettre, télécharger, envoyer, publier, échanger via l’application tout contenu à caractère personnel;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- porter atteinte d’une quelconque manière à MonOrdonnance et aux médecins ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- collecter, stocker, transmettre et / ou diffuser les données à caractère personnel des médecins ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- tenter d’induire en erreur les tiers en usurpant le nom d’une personne physique ou morale ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- télécharger, afficher, transmettre par tout moyen tout contenu comprenant des virus informatiques ou tout autre code, dossier ou programme conçus pour interrompre, détruire ou limiter la fonctionnalité de tout logiciel, ordinateur ou outil de télécommunication sans que cette énumération ne soit limitative ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- transmettre, télécharger, envoyer, publier, échanger, diffuser tout contenu à caractère obscène ;</p>
                                <p className="FooterdescriptionPargaraphe">En cas de non-respect de l’une des stipulations de cet article, MonOrdonnance se réserve le droit de suspendre ou de supprimer immédiatement sans préavis et sans indemnité le compte de l’Utilisateur.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 6 – OBLIGATIONS DE MONORDONNANCE</h2>
                                <p className="FooterdescriptionPargaraphe">Les Services sont accessibles vingt-quatre heures sur vingt-quatre, sept jours sur sept, sauf en cas d’événement de force majeure ou d’événements hors du contrôle de MonOrdonnance, et sous réserve des périodes de maintenance et de mise à jour nécessaires de l’application.</p>
                                <p className="FooterdescriptionPargaraphe">MonOrdonnance s’engage, dans le cadre d’une obligation de moyens, à fournir un accès aux  Services en respectant les CGU, à agir avec diligence et compétence, et à mettre tout en œuvre, dans une limite raisonnable, afin de remédier à tout dysfonctionnement porté à sa connaissance. MonOrdonnance est responsable de ses fautes dans la fourniture et l’exécution des Services, notamment au regard du bon fonctionnement de l’application.</p>
                                <p className="FooterdescriptionPargaraphe">Pour la bonne gestion des Services, MonOrdonnance pourra à tout moment :</p>
                                <p className="FooterdescriptionPargaraphe headlines">- suspendre, interrompre ou limiter l'accès à tout ou partie des Services, réserver l'accès aux Services ou à certaines parties des Services ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- supprimer toute information pouvant perturber le fonctionnement des Services ou entrant en contravention avec les lois nationales ou internationales et / ou les CGU ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- suspendre l’accès aux Services afin de procéder à des opérations de maintenance et / ou de mises à jour.</p>
                                <p className="FooterdescriptionPargaraphe">La responsabilité de MonOrdonnance ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès aux Services ou à l’une de ses fonctionnalités.</p>


                                <p className="FooterdescriptionPargaraphe">L’Utilisateur reconnaît et accepte que la responsabilité de MonOrdonnance ne saurait être engagée pour tous les inconvénients ou dommages liés à l'utilisation du réseau internet mobile, dont, sans que cette liste ne soit limitative :</p>
                                <p className="FooterdescriptionPargaraphe headlines">- la mauvaise transmission et / ou réception de toute donnée et / ou information sur le réseau internet mobile ou via les réseaux satellitaires ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- la défaillance de tout matériel de réception ou des lignes de communication ;</p>
                                <p className="FooterdescriptionPargaraphe headlines">- tout dysfonctionnement du réseau internet mobile ou des réseaux satellitaires empêchant le bon déroulement des Services.</p>
                                <p className="FooterdescriptionPargaraphe">Plus généralement, MonOrdonnance ne pourra en aucun cas être tenue pour responsable en raison d’une interruption des Services quel que soit la cause, la durée ou la fréquence de cette interruption.</p>

                                <p className="FooterdescriptionPargaraphe">En outre, l’Utilisateur reconnaît et accepte que la responsabilité de MonOrdonnance ne saurait être engagée pour :</p>
                                <p className="FooterdescriptionPargaraphe headlines">- toutes conséquences découlant d’informations manquantes, incomplètes, fausses, inexactes ou erronées communiquées par les Utilisateurs ;</p>

                                <p className="FooterdescriptionPargaraphe">MonOrdonnance s’engage à supprimer les contenus illicites, une fois que ceux-ci lui auront été signalés, notamment dans le cadre du respect de la loi 09-08 relative à la protection des personnes physiques à l’égard des traitements des données à caractère personnel et ses textes d’application.</p>
                                <p className="FooterdescriptionPargaraphe">De manière plus générale, MonOrdonnance s’engage à supprimer tout contenu qui ne respecterait  pas les CGU, et la responsabilité de MonOrdonnance vis-à-vis de l’Utilisateur ne saurait être engagée qu’en cas d’inexécution de ses engagements résultant des CGU.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 7 – PROPRIÉTÉ INTELLECTUELLE</h2>
                                <p className="FooterdescriptionPargaraphe">Tous les éléments des Services de MonOrdonnance restent la propriété exclusive de MonOrdonnance. Tous les logos, marques ou bases de données appartenant à MonOrdonnance ne peuvent pas être utilisés sans le consentement préalable écrit de MonOrdonnance.</p>
                                <p className="FooterdescriptionPargaraphe">Toute reproduction, exploitation, diffusion, modification, distribution, transmission, ou toute autre utilisation quelconque, totale ou partielle, des Services ou d’un de leurs éléments sans accord exprès et préalable de la Société est strictement interdit. Ces actes constituent une contrefaçon sur le fondement du code de la propriété intellectuelle ainsi qu’une concurrence déloyale sur le fondement du droit civil. Les sanctions qui y sont attachées peuvent être civiles et pénales.</p>
                                <p className="FooterdescriptionPargaraphe">Aucune stipulation des CGU ne peut être interprétée comme une cession de droits de propriété intellectuelle, que ce soit tacitement ou d’une autre façon.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>ARTICLE 8 – DISPOSITIONS DIVERSES</h2>
                                <h5 className="FooterdescriptionsubTitle">8.1. Données personnelles</h5>
                                <p className="FooterdescriptionPargaraphe">Conformément à la réglementation relative à la protection des données personnelles, MonOrdonnance est amenée à traiter des données personnelles des Utilisateurs en sa qualité de responsable du traitement. La politique de traitement des données personnelles se trouve à l’adresse suivante : https://monordonnance.sobrus.com/#privacypolicy</p>
                                <p className="FooterdescriptionPargaraphe">Pour toute question concernant le traitement de ses données ou ses droits sur celles-ci, l’Utilisateur peut contacter : <a>support@sobrus.com</a>.</p>
                                <h5 className="FooterdescriptionsubTitle">8.2. Force majeure</h5>
                                <p className="FooterdescriptionPargaraphe">En tout état de cause, MonOrdonnance ne saurait être tenue responsable de l’inexécution de ses obligations par suite d’une force majeure, d’un cas fortuit, sans que cette liste ne soit limitative, en cas de grève, d’inondation, d’incendie, de panne informatique, de panne électrique, etc.</p>

                                <h5 className="FooterdescriptionsubTitle">8.3. Preuve</h5>
                                <p className="FooterdescriptionPargaraphe">Les parties reconnaissent et conviennent que les SMS et les courriers électroniques échangés entre MonOrdonnance et l’Utilisateur dans le cadre de la fourniture des Services et tous autres écrits électroniques, issus de l’utilisation des Services constituent des documents originaux entre les parties feront preuve ; et seront admissibles devant les tribunaux, sous réserve que puisse être dûment identifiée la personne dont ils émanent et qu’ils soient établis et conservés dans des conditions permettant d’en garantir l’intégrité.</p>
                                <h5 className="FooterdescriptionsubTitle">8.4. Non-renonciation</h5>
                                <p className="FooterdescriptionPargaraphe">Le fait pour MonOrdonnance de ne pas se prévaloir à un moment donné d’une stipulation quelconque ne peut être considéré comme valant renonciation au bénéfice de cette stipulation ou au droit de se prévaloir ultérieurement de ladite stipulation et / ou de demander réparation pour tout manquement à cette stipulation.</p>
                                <h5 className="FooterdescriptionsubTitle">8.5. Nullité partielle</h5>
                                <p className="FooterdescriptionPargaraphe">Si une ou plusieurs stipulations des CGU sont déclarées nulles ou invalidées par une loi, un règlement ou une décision définitive d’une juridiction compétente, celles-ci seront réputées non écrites. Les autres stipulations des CGU conserveront toute leur validité et portée. La clause invalidée sera remplacée par une clause juridiquement valide dont l’esprit et la lettre seront aussi proches que possible de l’ancienne clause.</p>

                                <h5 className="FooterdescriptionsubTitle">8.6. Droit applicable et attribution de juridiction</h5>
                                <p className="FooterdescriptionPargaraphe">Le contrat formé par l'acceptation des CGU est soumis à la loi Marocaine. La langue des CGU et du contrat est le Français.</p>
                                <p className="FooterdescriptionPargaraphe">En cas de réclamation ou de litige concernant les Services, l’Utilisateur s’engage à s’adresser en priorité au service clientèle de MonOrdonnance, joignable par courrier électronique (<span>voir art. 8.7</span>) ou par courrier recommandé avec demande d’avis de réception à l’adresse suivante : <span>Villa N°222, Rue Tétouan, Harhoura - TEMARA</span> </p>
                                <p className="FooterdescriptionPargaraphe">Les parties s’engagent à rechercher un règlement amiable au différend concerné avant toute action judiciaire ou recours à une procédure de médiation.</p>
                                <p className="FooterdescriptionPargaraphe">À défaut d’accord amiable, tout litige relatif aux présentes conditions générales est soumis à la compétence exclusive des tribunaux dans le ressort duquel se trouve le siège social de l’entreprise.</p>

                                <h5 className="FooterdescriptionsubTitle">8.7. Contacter MonOrdonnance</h5>
                                <p className="FooterdescriptionPargaraphe">De manière générale, si l’Utilisateur a des questions ou des remarques concernant les CGU ou les Services, il peut nous contacter à l’adresse suivante : <a>support@sobrus.com</a>.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default termsOfService;
