import React, { Component } from 'react';
import "./landingPage.css";


class webSitePolitique extends Component {

    componentDidMount() {
        document.title = "MonOrdonnance -  Politique de confidentialité";
    }

    render() {
        return (
            <div className="webSitePolitique" id={this.props.id}>
                <section className="Landing_page_howitwork_wrapper">
                    <div className="Landing_page_howitwork_container">
                        <h1>POLITIQUE de <strong>CONFIDENTIALITÉ</strong></h1>
                        <div className="sep_title"></div>
                        <div className="privacyPolicyContent">
                            <div className="titlesToParagraphes">
                                <h2>A. Introduction</h2>
                                <p className="FooterdescriptionPargaraphe">1. La confidentialité des visiteurs de notre site web est très importante pour
                                nous, et nous nous engageons à la protéger. Cette politique détaille ce que
                            nous faisons de vos informations personnelles. </p>
                                <p className="FooterdescriptionPargaraphe">2. Consentir à notre utilisation de cookies en accord avec cette politique
                                lors de votre première visite de notre site web nous permet d’utiliser des
                                cookies à chaque fois que vous consultez notre site ou application pour
                            une meilleure expérience dans votre navigation.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>B. Collecte d’informations personnelles</h2>
                                <p className="FooterdescriptionPargaraphe">Les types d’informations personnelles suivants peuvent collectés, stockés
                            et utilisés :</p>
                                <p className="FooterdescriptionPargaraphe">1. Des informations à propos de votre ordinateur, y compris votre adresse IP, le type et la version de votre navigateur, et votre système d’exploitation;</p>
                                <p className="FooterdescriptionPargaraphe">2. Des informations sur vos visites et votre utilisation de ce site web y
                            compris la source référente, la durée de la visite, les pages vues, et les chemins de navigation sur le site web ;</p>
                                <p className="FooterdescriptionPargaraphe">3. Des informations comme votre adresse e-mail, que vous nous fournissez lors de votre inscription au site web ;</p>
                                <p className="FooterdescriptionPargaraphe">4. Des informations que vous saisissez quand vous créez un compte sur
                            notre site web comme votre nom et prénom, votre numéro de téléphone, votre spécialité et votre adresse ;</p>
                                <p className="FooterdescriptionPargaraphe">5. Des informations comme votre nom et votre adresse e-mail, que vous saisissez pour souscrire à nos e-mails et/ou newsletters ;</p>
                                <p className="FooterdescriptionPargaraphe">6. Des informations que vous saisissez quand vous utilisez les services de notre site web ;</p>
                                <p className="FooterdescriptionPargaraphe">7. Des informations générées lors de l’utilisation de notre site, y compris quand, à quelle fréquence et sous quelles circonstances vous l’utilisez ;</p>
                                <p className="FooterdescriptionPargaraphe">8. Des informations contenues dans toutes les communications que vous
                            nous envoyez par e-mail ou sur notre site web, y compris leurs contenus et leurs métadonnées ;</p>
                                <p className="FooterdescriptionPargaraphe">9. Toute autre information personnelle que vous nous communiquez</p>
                                <p className="FooterdescriptionPargaraphe">Avant de nous divulguer des informations personnelles concernant une
                                autre personne, vous devez obtenir le consentement de ladite personne en
                                ce qui concerne la divulgation et le traitement de ces informations
                            personnelles selon les termes de cette politique.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>C. Utilisation de vos informations personnelles</h2>
                                <p className="FooterdescriptionPargaraphe">Les informations personnelles qui nous sont fournies par le biais de notre
                                site web et notre application seront utilisées dans les objectifs
                            décrits dans cette politique ou dans les pages du site pertinentes. Nous pouvons utiliser vos informations personnelles pour : </p>
                                <p className="FooterdescriptionPargaraphe">1. Administrer notre site web et notre entreprise ;</p>
                                <p className="FooterdescriptionPargaraphe">2. Personnaliser notre site web pour vous ;</p>
                                <p className="FooterdescriptionPargaraphe">3. Permettre votre utilisation des services proposés sur notre outil ;</p>
                                <p className="FooterdescriptionPargaraphe">4. Vous envoyer des communications commerciales non relatives au marketing ;</p>
                                <p className="FooterdescriptionPargaraphe">5. Vous envoyer des notifications par e-mail que vous avez expressément demandées ;</p>
                                <p className="FooterdescriptionPargaraphe">6. Vous envoyer notre newsletter par e-mail (vous pouvez nous informer à tout moment de votre volonté de ne plus recevoir notre newsletter) ;</p>
                                <p className="FooterdescriptionPargaraphe">7. Vous envoyer des communications marketing relatives à notre entreprise
                                ou à des entreprises tierces sélectionnées avec soin qui selon nous
                                pourraient vous intéresser, sous forme de publication, ou si vous avez
                                expressément donné votre accord, par e-mail ou technologie similaire
                            (vous pouvez nous informer à tout moment de votre volonté de ne plus recevoir de communications marketing) ;</p>
                                <p className="FooterdescriptionPargaraphe">8. Traiter les demandes et les réclamations relatives à votre site web effectuées par vous ou vous concernant ;</p>
                                <p className="FooterdescriptionPargaraphe">9. Maintenir la sécurité de notre site web et empêcher la fraude ;</p>
                                <p className="FooterdescriptionPargaraphe">10. Vérifier le respect des conditions générales qui régissent l’utilisation de notre site web ;</p>
                                <p className="FooterdescriptionPargaraphe">Si vous soumettez des informations personnelles sur notre site web dans le
                            but de les publier, nous les publierons et pourrons utiliser ces informations conformément aux autorisations que vous nous accordez</p>
                                <p className="FooterdescriptionPargaraphe">Vos paramètres de confidentialité peuvent être utilisés pour limiter la
                            publication de vos informations sur notre site web et peuvent être ajustés dans vos paramètres de confidentialité sur le site web.</p>
                                <p className="FooterdescriptionPargaraphe">Sans votre consentement explicite, nous ne fournirons pas vos informations personnelles à des tierces parties pour leur marketing direct, ni celui d’autres tierces parties.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>D. Transferts internationaux de données</h2>
                                <p className="FooterdescriptionPargaraphe">1. Les informations que nous collectons peuvent être stockées, traitées et
                            transférées dans tous les pays dans lesquels nous opérons, afin de nous permettre d’utiliser les informations en accord avec cette politique. </p>
                                <p className="FooterdescriptionPargaraphe">2. Vous acceptez expressément le transfert d’informations personnelles décrit dans cette Section D.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>E. Conservation de vos informations personnelles</h2>
                                <p className="FooterdescriptionPargaraphe">1. Cette Section E détaille nos politiques de conservation des données et
                                nos procédures, conçues pour nous aider à nous conformer à nos
                            obligations légales concernant la conservation et la suppression d’informations personnelles </p>
                                <p className="FooterdescriptionPargaraphe">2. Les informations personnelles que nous traitons à quelque fin que ce
                            soit ne sont pas conservées plus longtemps que nécessaire à cette fin ou à ces fins.</p>
                                <p className="FooterdescriptionPargaraphe">3. Nonobstant les autres dispositions de cette Section E, nous
                                conserverons des documents (y compris des documents électroniques)
                            contenant des données personnelles dans la mesure où nous sommes tenus de le faire par la loi ;</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>F. Sécurité de vos informations personnelles</h2>
                                <p className="FooterdescriptionPargaraphe">1. Nous prendrons des précautions techniques et organisationnelles raisonnables pour empêcher la perte, l’abus ou l’altération de vos informations personnelle</p>
                                <p className="FooterdescriptionPargaraphe">2. Nous stockerons toutes les informations personnelles que vous nous
                                fournissez sur des serveurs sécurisés (cryptés, protégés par mot de passe
                            et pare-feu).</p>
                                <p className="FooterdescriptionPargaraphe">3. Vous êtes responsable de la confidentialité du mot de passe que vous
                            utilisez pour accéder à notre site web ; nous ne vous demanderons pas votre mot de passe (sauf quand vous vous identifiez sur notre site web).</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>G. Amendements</h2>
                                <p className="FooterdescriptionPargaraphe">Nous pouvons parfois mettre cette politique à jour en publiant une nouvelle
                            version sur notre site web. <br></br>Vous devez vérifier cette page régulièrement
                            pour vous assurer de prendre connaissance de tout changement effectué à cette politique.<br></br> Nous pouvons vous informer des changements effectués à cette politique par courrier électronique ou par le biais du service de messagerie privée de notre site web</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>H. Vos droits</h2>
                                <p className="FooterdescriptionPargaraphe">Vous pouvez nous demander de vous fournir toute information personnelle
                            que nous détenons sur vous ; </p>
                                <p className="FooterdescriptionPargaraphe">
                                    Nous pouvons retenir les informations personnelles que vous demandez
                            dans la mesure autorisée par la loi.</p>
                                <p className="FooterdescriptionPargaraphe">
                                    Vous pouvez nous demander à tout moment de ne pas traiter vos
                            informations personnelles à des fins marketing.</p>
                                <p className="FooterdescriptionPargaraphe">
                                    En pratique, vous exprimerez expressément et à l’avance votre accord
                                    pour que nous utilisions vos informations personnelles à des
                                    fins marketing ou nous vous fournirons une opportunité de refuser
                            l’utilisation de vos informations personnelles à des fins marketing.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>I. Mise à jour des informations</h2>
                                <p className="FooterdescriptionPargaraphe">Merci de nous faire savoir si les informations personnelles que nous détenons à votre sujet doivent être corrigées ou mises à jour.</p>
                            </div>
                            <div className="titlesToParagraphes">
                                <h2>J. Cookies</h2>
                                <p className="FooterdescriptionPargaraphe">Notre site web utilise des cookies. Un cookie est un fichier contenant un
                                identifiant (une chaîne de lettres et de chiffres) envoyé par un serveur web
                                vers un navigateur web et stocké par le navigateur. L’identifiant est alors
                                renvoyé au serveur à chaque fois que le navigateur demande une page au
                                serveur. Les cookies peuvent être « persistants » ou « de session » : un
                                cookie persistant est stocké par le navigateur et reste valide jusqu’à sa
                                date d’expiration, à moins d’être supprimé par l’utilisateur avant cette date
                                d’expiration ; quant à un cookie de session, il expire à la fin de la session
                                utilisateur, lors de la fermeture du navigateur. Les cookies ne contiennent
                                en général aucune information permettant d’identifier personnellement un
                            utilisateur.</p>
                                <p className="FooterdescriptionPargaraphe">1. Les noms des cookies que nous utilisons sur notre site web et les
                            objectifs dans lesquels nous les utilisons sont décrits ci-dessous:</p>
                                <p className="FooterdescriptionPargaraphe">2. La plupart des navigateurs vous permettent de refuser ou d’accepter les
                            cookies.</p>
                                <p className="FooterdescriptionPargaraphe">Bloquer tous les cookies aura un impact négatif sur l’utilisation de
                            nombreux sites web. Si vous bloquez les cookies, vous ne pourrez pas utiliser toutes les fonctionnalités de notre site web.</p>
                                <p className="FooterdescriptionPargaraphe">3. Vous pouvez supprimer les cookies déjà stockés sur votre ordinateur.
                            Supprimer les cookies aura un impact négatif sur l’utilisation de nombreux sites web.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default webSitePolitique;
